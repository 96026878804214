
<template>
    

    <div class="px-5 md:px-10 py-14 w-full">
        <div class="w-full">
            <div class="flex font-bold text-4xl">
                <h2 class="text-custom-yellow relative border-b-4 pb-2 border-b-4 border-custom-yellow hover:border-b-4 hover:border-white">Notre Equipe</h2>
            </div>
            <!-- <div class="flex items-center mt-5">
                <hp class="mx-auto relative">Une équipe bien expérimentée et dévouée pour votre satisfaction</hp>
            </div> -->

            <div class="md:grid md:grid-cols-4 md:gap-14 mt-5 justify-between">
                  
                <div class="bg-white mt-5 md:mt-10 border pb-2">
                    <div class="flex items-center">
                        <img class="mx-auto w-full" src="https://pattechconsulting.com/images/teams/patrick.JPG" alt>
                    </div>
                    <div class="flex items-center mt-5">
                        <h3 class="mx-auto font-bold text-custom-yellow">Patrick Mugisho</h3>
                    </div>
                    <div class="flex items-center mt-2 bg-gray-50 bg-opacity-30 px-2 text-gray-500 text-sm">
                        <p class="mx-auto">CEO et Ingénieure système</p>
                    </div>
                    <div class="flex items-center bg-gray-50 bg-opacity-30 px-2 text-gray-500 text-sm">
                        <!-- <p class="mx-auto">+243 970 217 554</p> -->
                    </div>
                </div>  

                <div class="bg-white mt-5 md:mt-10 border pb-2">
                    <div class="flex items-center">
                        <img class="mx-auto  w-full" src="https://pattechconsulting.com/images/teams/philippe.JPG" alt>
                    </div> 
                    <div class="flex items-center mt-5">
                        <h3 class="mx-auto font-bold text-custom-yellow">Philippe Tsongo</h3>
                    </div>
                    <div class="flex items-center mt-2 bg-gray-50 bg-opacity-30 px-2 text-gray-500 text-sm">
                        <p class="mx-auto">Project Manager & Sofware Engineer</p>
                    </div>
                    <div class="flex items-center bg-gray-50 bg-opacity-30 px-2 text-gray-500 text-sm">
                        <!-- <p class="mx-auto">+243 990 741 752 / +250 783 793 095</p> -->
                    </div>
                </div>
                
                <div class="bg-white mt-5 md:mt-10 border pb-2">
                    <div class="flex items-center">
                        <img class="mx-auto w-full" src="https://pattechconsulting.com/images/teams/yala.JPG" alt>
                    </div>
                    <div class="flex items-center mt-5">
                        <h3 class="mx-auto font-bold text-custom-yellow">Gedeon YALA</h3>
                    </div>
                    <div class="flex items-center mt-2 bg-gray-50 bg-opacity-30 px-2 text-gray-500 text-sm">
                        <p class="mx-auto">Full-Stacks Developer</p>
                    </div>
                    <div class="flex items-center bg-gray-50 bg-opacity-30 px-2 text-gray-500 text-sm">
                        <!-- <p class="mx-auto">+256 763 496042 </p> -->
                    </div>
                </div>

                <div class="bg-white mt-5 md:mt-10 border pb-2">
                    <div class="flex items-center">
                        <img class="mx-auto  w-full" src="https://pattechconsulting.com/images/teams/sam.JPG" alt>
                    </div>
                    <div class="flex items-center mt-5">
                        <h3 class="mx-auto font-bold text-custom-yellow">Samy Ndabo</h3>
                    </div>
                    <div class="flex items-center mt-2 bg-gray-50 bg-opacity-30 px-2 text-gray-500 text-sm">
                        <p class="mx-auto">Full-Stacks Developer</p>
                    </div>
                    <div class="flex items-center bg-gray-50 bg-opacity-30 px-2 text-gray-500 text-sm">
                        <!-- <p class="mx-auto">+243 973 886 132</p> -->
                    </div>
                </div>

                <div class="bg-white mt-5 md:mt-10 border pb-2">
                    <div class="flex items-center">
                        <img class="mx-auto  w-full" src="https://pattechconsulting.com/images/teams/joseph.JPG" alt>
                    </div>
                    <div class="flex items-center mt-5">
                        <h3 class="mx-auto font-bold text-custom-yellow">Joseph Tambwe</h3>
                    </div>
                    <div class="flex items-center mt-2 bg-gray-50 bg-opacity-30 px-2 text-gray-500 text-sm">
                        <p class="mx-auto">Analyste de données</p>
                    </div>
                    <div class="flex items-center bg-gray-50 bg-opacity-30 px-2 text-gray-500 text-sm">
                        <!-- <p class="mx-auto">+243 973 886 132</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
/* eslint-disable */

export default {
  name: "TeamComponent",

  data() {
    return {
    };
  },

  mounted() {
  },

  methods: {
    
  }
};
</script>

<style>
</style>