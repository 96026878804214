<template>
    <div class="w-full bg-no-repeat bg-cover" style="background-image: url('https://pattechconsulting.com/images/footer.jpg');">
        <div class="w-full bg-black px-5 md:px-10 md:px-10 py-14 bg-opacity-50 text-white">
            <div class="flex font-bold text-4xl">
                <h2 class="relative text-white border-b-4 pb-2 border-white hover:border-b-4 hover:border-white">Nos Réalisations</h2>
            </div>
            <div class="flex items-center mt-5">
                <p class="mx-auto relative">Découvrez quelques-uns de nos projets réalisés pour des clients de divers secteurs.</p>
            </div>
            
            <div class="md:grid md:grid-cols-2 justify-between gap-5 mt-14">
                <div class="md:flex gap-5 justify-between text-gray-700 bg-white">
                    <div class="md:w-2/4 md:p-5">
                        <img class="w-full" src="https://pattechconsulting.com/images/constructions-1.jpg" alt>
                    </div>
                    <div class="md:w-2/4 p-5">
                        <h3 class="font-bold text-lg text-custom-yellow">Construction d'un centre commercial high-tech</h3>
                        <p class="mt-2">Fuga in dolorum et iste et culpa. Commodi possimus nesciunt modi voluptatem placeat deleniti adipisci. Cum delectus</p>
                    </div>
                </div>

                <div class="md:flex gap-5 xs:mt-5 md:mt-0 justify-between border text-gray-700 bg-white">
                    <div class="md:w-2/4 md:p-5">
                        <img class="w-full" src="https://pattechconsulting.com/images/constructions-2.jpg" alt>
                    </div>
                    <div class="md:w-2/4 p-5">
                        <h3 class="font-bold text-lg text-custom-yellow">Rénovation d'un hôpital historique</h3>
                        <p class="mt-2">Fuga in dolorum et iste et culpa. Commodi possimus nesciunt modi voluptatem placeat deleniti adipisci. Cum delectus </p>
                    </div>
                </div>
            <!-- </div>

            <div class="md:flex justify-between gap-5 mt-5 px-5 md:px-14"> -->
                <div class="md:flex gap-5 justify-between border text-gray-700 bg-white">
                    <div class="md:w-2/4 md:p-5">
                        <img class="w-full" src="https://pattechconsulting.com/images/constructions-3.jpg" alt>
                    </div>
                    <div class="md:w-2/4 p-5">
                        <h3 class="font-bold text-lg text-custom-yellow">Aménagement d'une autoroute durable</h3>
                        <p class="mt-2">Fuga in dolorum et iste et culpa. Commodi possimus nesciunt modi voluptatem placeat deleniti adipisci. Cum delectus</p>
                    </div>
                </div>

                <div class="md:flex gap-5 xs:mt-5 md:mt-0 justify-between border text-gray-700 bg-white">
                    <div class="md:w-2/4 md:p-5">
                        <img class="w-full" src="https://pattechconsulting.com/images/constructions-2.jpg" alt>
                    </div>
                    <div class="md:w-2/4 p-5">
                        <h3 class="font-bold text-lg text-custom-yellow">Rénovation d'un hôpital historique</h3>
                        <p class="mt-2">Fuga in dolorum et iste et culpa. Commodi possimus nesciunt modi voluptatem placeat deleniti adipisci. Cum delectus </p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        name: 'RealisationComponent'
    }

</script>

<style>

</style>