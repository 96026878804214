<template>
    <div class="md:flex bg-gray-100 py-10">

        <div class="px-8 md:w-1/3">
            <div class="text-custom-yellow py-5 md:py-0 font-bold text-2xl md:text-4xl w-2/3">
                Nos services
            </div>

            <p class="xs:mt-5 md:mt-10">
                Pattech Consulting est une entreprise de services IT qui accompagne ses clients dans leur transformation numérique depuis plus de 8 ans. Nous mettons notre expertise technique et notre savoir-faire sectoriel au service de la réussite de vos projets informatiques.
            </p>
        </div>
        <div class="px-8 md:w-2/3">
            <div class="flex border-b mt-10 border-gray-300 py-8 justify-between w-full hover:bg-gray-100 hover:cursor-pointer">
                <div class="w-2/4 font-bold text-xl md:text-2xl hover:text-custom-yellow hover:cursor-pointer" @mouseover="showImage1 = true" @mouseleave="showImage1 = false">
                    Développement d'applications
                </div>
                <div class="text-gray-800 w-2/4">
                    Nous concevons, programmons, testons, déployons, maintenons et améliorons continuellement les applications qui répondent aux besoins spécifiques des utilisateurs.
                </div>
                <div v-if="showImage1" class="absolute flex justify-between left-1/2">
                    <img src="https://pattechconsulting.com/images/dev.jpg" alt="Pattech Consulting. Go forward through technology" class="w-48 h-44" />
                </div>
            </div>

            <div class="flex border-b border-gray-300 py-8 justify-between w-full hover:bg-gray-100 hover:cursor-pointer">
                <div class="w-2/4 font-bold text-xl md:text-2xl hover:text-custom-yellow hover:cursor-pointer" @mouseover="showImage2 = true" @mouseleave="showImage2 = false">
                    Conception des Sites web, Applications Android et IOS
                </div>
                <div class="text-gray-800 w-2/4">
                    Nous concevons, programmons, testons, déployons et maintenons des <b>sites web, applications Android et iOS</b>, avec performance, sécurité et support technique.
                </div>
                <div v-if="showImage2" class="absolute flex justify-between left-1/2">
                    <img src="https://pattechconsulting.com/images/web-site.jpg" alt="Pattech Consulting. Go forward through technology" class="w-48 h-44" />
                </div>
            </div>

            <div class="flex border-b border-gray-300 py-8 justify-between w-full hover:bg-gray-100 hover:cursor-pointer">
                <div class="w-2/4 font-bold text-xl md:text-2xl hover:text-custom-yellow hover:cursor-pointer" @mouseover="showImage3 = true" @mouseleave="showImage3 = false">
                    Infogérance et support IT
                </div>
                <div class="text-gray-800 w-2/4">
                    Confiez-nous la gestion et la maintenance de tout ou une partie de votre système d'information pour la superviser, la sécuriser, la mettre à jour et développer de nouvelles fonctionnalités, pour ainsi bénéficier de notre expertise spécialisée.
                </div>
                <div v-if="showImage3" class="absolute flex justify-between left-1/2">
                    <img src="https://pattechconsulting.com/images/support.jpg" alt="Pattech Consulting. Go forward through technology" class="w-48 h-44" />
                </div> 
            </div>

            <div class="flex border-b border-gray-300 py-8 justify-between w-full hover:bg-gray-100 hover:cursor-pointer">
                <div class="w-2/4 font-bold text-xl md:text-2xl hover:text-custom-yellow hover:cursor-pointer" @mouseover="showImage4 = true" @mouseleave="showImage4 = false">
                    Audit et analyse de données
                </div>
                <div class="text-gray-800 w-2/4">
                    Nous offrons des services d'audit et d'analyse de données, comprenant l'évaluation de vos systèmes, l'identification des inefficacités, la sécurisation des données et l'optimisation des performances pour des prises de décisions éclairées.
                </div>
                <div v-if="showImage4" class="absolute flex justify-between left-1/2">
                    <img src="https://pattechconsulting.com/images/data-analysis.jpg" alt="Pattech Consulting. Go forward through technology" class="w-48 h-44" />
                </div>
            </div>
            <div class="flex border-b border-gray-300 py-8 justify-between w-full hover:bg-gray-100 hover:cursor-pointer">
                <div class="w-2/4 font-bold text-xl md:text-2xl hover:text-custom-yellow hover:cursor-pointer" @mouseover="showImage5 = true" @mouseleave="showImage5 = false">
                    Conseil en transformation numérique
                </div>
                <div class="text-gray-800 w-2/4">
                    Nous proposons des services de conseil en transformation numérique, aidant votre entreprise à adopter les technologies modernes, optimiser les processus et améliorer l'efficacité opérationnelle pour rester compétitive dans un monde digital.
                </div>
                <div v-if="showImage5" class="absolute flex justify-between left-1/2">
                    <img src="https://pattechconsulting.com/images/support.jpg" alt="Pattech Consulting. Go forward through technology" class="w-48 h-44" />
                </div>
            </div>
            <div class="flex border-b border-gray-300 py-8 justify-between w-full hover:bg-gray-100 hover:cursor-pointer">
                <div class="w-2/4 font-bold text-xl md:text-2xl hover:text-custom-yellow hover:cursor-pointer" @mouseover="showImage6 = true" @mouseleave="showImage6 = false">
                    Réseau et Cyber sécurité
                </div>
                <div class="text-gray-800 w-2/4">
                    Nous offrons des services de sécurité réseau et cybersécurité, protégeant vos données, systèmes et infrastructures contre les menaces en ligne, assurant ainsi une exploitation sécurisée et fiable de vos activités numériques.
                </div>
                <div v-if="showImage6" class="absolute flex justify-between left-1/2">
                    <img src="https://pattechconsulting.com/images/cyber-security.jpg" alt="Pattech Consulting. Go forward through technology" class="w-48 h-44" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */




export default {
  name: "ServiceComponent",

  data() {
    return {
    
        showImage1: false,
        showImage2: false,
        showImage3: false,
        showImage4: false,
        showImage5: false,
        showImage6: false,
    };
  },

  mounted(){
    
  },

  methods: {
    
  }
};
</script>

<style>
</style>