<template>
	<div>
		<router-view/>
	</div>
</template>

<script>
export default {
	name: "App",
	components: {}

	/* eslint-disable */

};
</script>

<style>

</style>
