import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import ContactView from '../views/ContactView.vue';
import ServiceView from '../views/ServiceView.vue';
import ProjectView from '../views/ProjectView.vue';



const routes = [
  // Define your routes here
  { path: '/', component: HomeView, name: "home" },
  { path: '/about', component: AboutView, name: "about" },
  { path: '/contact', component: ContactView, name: "contact" },
  { path: '/services', component: ServiceView, name: "service" },
  { path: '/projects', component: ProjectView, name: "project" },


  // more routes as needed
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});


export default router;
