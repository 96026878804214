/* eslint-disable */

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import './assets/styles/tailwindcss.css';
import '@fortawesome/fontawesome-free/css/all.css';

const apiBaseUrl = 'http://127.0.0.1:8000/api'; //local


createApp(App).use(router).mount('#app')