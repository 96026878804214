<template>
	<div class="bg-cover bg-center bg-no-repeat bg-custom-yellow" style="background-image: url('https://pattechconsulting.com/images/footer.jpg');">	
		<div class="py-14 px-5 md:px-24 text-white justify-between z-10 bg-black bg-opacity-80 text-white" >
			<div class="uppercase mt-5 font-bold text-2xl text-custom-yellow">Pattech Consulting.</div>

			<div class="md:flex justify-between md:gap-14 pb-14 border-b border-gray-400">
				<div class="md:w-1/3">
					<div class="md:block mt-5 text-sm">RDC, Nord-Kivu, Goma</div>
					<div class="md:block text-sm">No. 12, Av. du Port, Quartier le Volcan, Commune de Goma, (A cote dela Pharmacie Diocésaine),</div>
					<div class="mt-5 text-sm"><span class="font-bold">Phone:</span> +243 970 217 554</div>
					<div class="text-sm"><span class="font-bold">Email:</span> contact@pattechconsulting.com </div>
				</div>

				<div class="md:w-1/3">
					<div class="uppercase mt-5 font-bold text-custom-yellow text-md">Pages</div>
					<li class="mt-2 text-sm">
						<router-link :to="{ name: 'home'}">
							Accueil
						</router-link>
					</li>
					<li class="md:mt-2 text-sm">
						<router-link :to="{ name: 'about'}">
							A propos
						</router-link>
					</li>
					<li class="md:mt-2 text-sm">
						<router-link :to="{ name: 'service'}">
							Services
						</router-link>
					</li>
					<!-- <li class="md:mt-2 text-sm">
						<router-link :to="{ name: 'project'}">
							Projets
						</router-link>
					</li> -->
				</div>

				<div class="md:w-1/3">
					<div class="uppercase mt-5 font-bold text-md text-custom-yellow">Services</div>
					<li class="mt-2 text-sm">Développement d'applications</li>
					<li class="mt-2 text-sm">Sites web et applications mobiles</li>
					<li class="mt-2 text-sm">Infogérance et support IT</li>
					<li class="mt-2 text-sm">Audit et analyse de données</li>
					<li class="mt-2 text-sm">Conseil en transformation numérique</li>
					<li class="mt-2 text-sm">Cybersécurité</li>

				</div>
			</div>
			
			<div class="flex items-center mt-10">
				<div class="mt-5 mx-auto text-xs">
					<p>©2024 Pattech Consulting. Tous droits réservés.</p>
					<div class="flex items-center">
						<p class="mx-auto">Designed by <span class="text-custom-yellow">Pattech Consulting.</span></p>
					</div>
				</div>

			</div>
		</div>		
	</div>
	
</template>

<script>
/* eslint-disable */



export default {
  name: "FooterComponent",

  data() {
    return {
    };
  },

  mounted(){
  },

  methods: {
    
  }
};
</script>

<!-- "scoped" attribute to limit CSS to this component only -->
<style>
</style>

