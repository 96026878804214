<template>
    <div>
        <Header-component :titleMessage="indice"></Header-component>


        <div class="">	
            <div class="md:flex justify-between px-8 md:px-14 mt-14 md:mt-24 w-full pb-10">
                <div class="md:w-2/4">    
                    <div class="text-custom-yellow font-bold text-4xl">Pattech Consulting</div>
                    <p class="mt-14  py-5 text-md md:mr-36">
                        Pattech Consulting est une entreprise de services IT qui accompagne ses clients dans leur transformation numérique depuis plus de 8 ans. Nous mettons notre expertise technique et notre savoir-faire sectoriel au service de la réussite de vos projets informatiques.
                    </p>

                </div>
                <div class="md:w-2/4">
                    <img src="https://pattechconsulting.com/images/logo/logo-no-bg.jpg" class="shadow-xl border-4 border-white">
                </div>
            </div>	
            
            <ServiceComponent></ServiceComponent>


            <TeamComponent></TeamComponent>

            <div class="px-5 md:px-14 bg-gray-100 gap-4 mt-10 md:mt-10 w-full">
                <div class="md:flex w-full md:gap-10 py-14 md:justify-between ">
                    <div class="bg-white md:w-1/4 text-center p-5">
                        <p class="font-bold text-4xl text-custom-yellow">8</p>
                        <p class="text-gray-400 mt-2">Années d'expérience</p>
                    </div>
                    <div class="mt-5 md:mt-0 bg-white md:w-1/4 text-center p-5">
                        <p class="font-bold text-4xl text-custom-yellow">6</p>
                        <p class="text-gray-400 mt-2">Services</p>
                    </div>
                    <div class="mt-5 md:mt-0 bg-white md:w-1/4 text-center p-5">
                        <p class="font-bold text-4xl text-custom-yellow">24H / 5J</p>
                        <p class="text-gray-400 mt-2">Disponibilité</p>                        
                    </div>
                    <div class="mt-5 md:mt-0 bg-white md:w-1/4 text-center p-5">
                        <p class="font-bold text-4xl text-custom-yellow">8</p>
                        <p class="text-gray-400 mt-2">Projets</p>                                                
                    </div>
                </div>
            </div>	

            <!-- <div class="md:flex justify-between px-8 md:px-14 gap-4 mt-10 md:mt-14 w-full">
                <div class="md:w-2/4 pb-10">
                    <img src="https://pattechconsulting.com/images/constructions-1.jpg" class="">
                </div>
                <div class="md:w-2/4 pb-10">
                    <img src="https://pattechconsulting.com/images/constructions-2.jpg" class="">
                </div>
            </div>	 -->
            
            
            <!-- <TestimonyComponent></TestimonyComponent> -->

            <footer-component></footer-component>
        </div>
    </div>

</template>

<script>
/* eslint-disable */
import HeaderComponent from "../../src/components/HeaderComponent.vue";
import FooterComponent from "../../src/components/FooterComponent.vue";
import TestimonyComponent from "../../src/components/TestimonyComponent.vue";
import TeamComponent from "../../src/components/TeamComponent.vue";
import ServiceComponent from "../../src/components/ServiceComponent.vue";


export default {
  name: "AboutView",
  components: {
    HeaderComponent,
    FooterComponent,
    TestimonyComponent,
    TeamComponent,
    ServiceComponent,
  },

  data() {
    return {
      showImage1: false,
      showImage2: false,
      showImage3: false,

      indice: "A propos"
    };
  },

  mounted() {},

  methods: {}
};
</script>

<style>
</style>