<template>
	<div>
		<div class="bg-cover bg-center bg-no-repeat bg-white" style="background-image: url('https://pattechconsulting.com/images/footer.jpg')">
			<div class="w-full h-100vh bg-black bg-opacity-80  justify-between p-5">
				
				<div class="mx-auto flex px-2 justify-between items-center text-white">
					<div class="container flex justify-between items-center  relative bg-cover bg-center">
						
						<!-- Logo -->
						<div class="flex items-center">
							<router-link to="/">
								<img class="rounded-full w-16 h-16" src="https://pattechconsulting.com/images/logo/logo-no-bg.jpg">
							</router-link>
						</div>

						<!-- Menu -->
						<nav class="xs:hidden lg:flex sm:ml-48 space-x-5 text-white">
							<router-link to="/" class="hover:text-custom-yellow">Accueil</router-link>
							<router-link :to="{ name: 'service'}" class="hover:text-custom-yellow">Services</router-link>
							<!-- <router-link :to="{ name: 'project'}" class="hover:text-custom-yellow">Projets</router-link> -->
							<router-link :to="{ name: 'about'}" class="hover:text-custom-yellow">A propos</router-link>
							<router-link :to="{ name: 'contact'}" class="hover:text-custom-yellow">Contact</router-link>
						</nav>
						
					</div>
					<div class="mx-2">
						<!-- Search icon --> 
						<div class="relative flex space-x-8 text-white">
							<button v-if="!showMenu" @click="menuModal" class="focus:outline-none">
								<i class="fas fa-bars border px-2 py-1 rounded-full"></i>
							</button>
						</div>
					</div>
				</div>
				<div v-if="showMenu" class="absolute border-blue-500 shadow-md bg-black bg-opacity-20 text-white z-10 right-0 xs:w-36 md:w-62 px-5 px-5 p-2 md:py-5 h-auto bg-white top-0">
					<div class="flex">

						<div class="flex">
							<button v-if="showMenu" class="right-0 focus:outline-none" @click="menuModal" style="color:#DC3545;">
								<i class="fa fa-xmark"></i> 
							</button>
						</div>
					</div>
					
					<ul class="mt-5">
						<li class="mt-4 ">
							<router-link to="/" class=" hover:text-custom-yellow">Accueil</router-link>
						</li>
						<li class="mt-4">
							<router-link :to="{ name: 'service'}" class="hover:text-custom-yellow">Services</router-link>
						</li>
						
						<!-- <li class="mt-4">
							<router-link :to="{ name: 'project'}" class="hover:text-custom-yellow">Projets</router-link>
						</li> -->
						<li class="mt-4">
							<router-link :to="{ name: 'about'}" class=" hover:text-custom-yellow">A propos</router-link>
						</li>
						<li class="mt-4">
							<router-link :to="{ name: 'contact'}" class="hover:text-custom-yellow">Contact</router-link>
						</li>
					</ul>

					

				</div>  

				<div class="flex item-center w-full mt-24">
					<h2 class="mx-auto font-bold xs:text-2xl md:text-4xl text-white">Pattech Consulting</h2>
				</div>
				<div class="flex item-center w-full mt-5">
					<div class="mx-auto font-bold xs:text-xl md:text-2xl italic text-white"> Go Forward Through the Technology</div>
				</div>
				<div class="flex item-center w-full mt-24">
					<router-link :to="{ name: 'contact'}" class="mx-auto font-bold text-md text-white bg-custom-yellow rounded-full p-2 w-48 text-center hover:cursor-pointer">Demandez un Devis<i class="fas fas-arrow-right"></i> </router-link>
				</div>

				
				<div class="md:flex md:item-center text-white md:border-b-2 border-white py-5 text-2xl justify-between px-10 w-full mt-14">
					<div class="md:border-0 xs:border xs:p-2 rounded">
						<span class="text-xl">+243 970 217 554</span><br>
						<span class="text-xl">Appelez-Nous</span> 
					</div>

					<div class="xs:mt-5 md:mt-0 xs:border md:border-0 xs:p-2 rounded">
						<span class="text-xl">8ans</span><br>
						<span class="text-xl">Années d'expérience</span>
					</div>

					<div class="xs:mt-5 md:mt-0 xs:border md:border-0 xs:p-2 rounded">
						<span class="text-xl">24H / 5J</span><br>
						<span class="text-xl">Disponibilité</span>
						
					</div>


				</div>
			</div>
		</div>

		<ServiceComponent></ServiceComponent>

		
		<div class="w-full absolute mb-24" id="construction">
		
			<!-- <RealisationComponent></RealisationComponent> -->

			
			<TeamComponent></TeamComponent>

			<div class="px-5 md:px-14 bg-gray-100 gap-4 mt-10 md:mt-10 w-full">
                <div class="md:flex w-full md:gap-10 py-14 md:justify-between ">
                    <div class="bg-white md:w-1/4 text-center p-5">
                        <p class="font-bold text-4xl text-custom-yellow">8</p>
                        <p class="text-gray-400 mt-2">Années d'expérience</p>
                    </div>
                    <div class="mt-5 md:mt-0 bg-white md:w-1/4 text-center p-5">
                        <p class="font-bold text-4xl text-custom-yellow">6</p>
                        <p class="text-gray-400 mt-2">Services</p>
                    </div>
                    <div class="mt-5 md:mt-0 bg-white md:w-1/4 text-center p-5">
                        <p class="font-bold text-4xl text-custom-yellow">24H / 5J</p>
                        <p class="text-gray-400 mt-2">Disponibilité</p>                        
                    </div>
                    <div class="mt-5 md:mt-0 bg-white md:w-1/4 text-center p-5">
                        <p class="font-bold text-4xl text-custom-yellow">8</p>
                        <p class="text-gray-400 mt-2">Projets</p>                                                
                    </div>
                </div>
            </div>	

			<!-- <TestimonyComponent></TestimonyComponent> -->

			<footer-component></footer-component>
			

		</div>


	</div>
	
</template>
<script>
/* eslint-disable */

import FooterComponent from "../components/FooterComponent.vue";
import ServiceComponent from "../components/ServiceComponent.vue";
import TestimonyComponent from "../components/TestimonyComponent.vue";
import RealisationComponent from "../components/RealisationComponent.vue";
import TeamComponent from "../components/TeamComponent.vue";


export default {
  name: "HomeView",

  components: {
    FooterComponent,
    ServiceComponent,
    TestimonyComponent,
    RealisationComponent,
	TeamComponent,
  },

  data() {
    return {
      showMenu: false,
      items: [
        { imageUrl: "https://pattechconsulting.com/images/home/hero-carousel-3.jpg" },
        { imageUrl: "https://pattechconsulting.com/images/home/hero-carousel-2.jpg" },
        { imageUrl: "https://pattechconsulting.com/images/home/hero-carousel-3.jpg" },
        { imageUrl: "https://pattechconsulting.com/images/home/hero-carousel-4.jpg" },
        { imageUrl: "https://pattechconsulting.com/images/home/hero-carousel-5.jpg" }
      ],

      showImage1: false,
      showImage2: false,
      showImage3: false,
      showImage4: false,
      showImage5: false,
      showImage6: false,


      currentIndex: 0,

      items2: [
        {
          title1: "Design",
          content1: [
            "I don’t know what else to say, this is something that you haven’t seen before. Unique design, impressive & outstanding support."
          ],
          title2: "Advertising",
          content2: [
            "This is something that you haven’t seen before. Unique design, impressive & outstanding support. I don’t know what else to say,"
          ],
          title3: "Design",
          content3: [
            "I don’t know what else to say, this is something that you haven’t seen before. Unique design, impressive & outstanding support."
          ],
          title4: "Advertising",
          content4: [
            "This is something that you haven’t seen before. Unique design, impressive & outstanding support. I don’t know what else to say,"
          ]
        }
      ],
      autoplayInterval: null
    };
  },

  created() {
    this.startAutoplay();
  },

  methods: {
    menuModal() {
      this.showMenu = !this.showMenu;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.items.length) % this.items.length;
    },

    startAutoplay() {
      this.autoplayInterval = setInterval(this.nextSlide, 4000); // Change slide every 5 seconds (adjust as needed)
    },
    stopAutoplay() {
      clearInterval(this.autoplayInterval);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
    }
  }
};
</script>

<style>
.carousel-item {
  transition: opacity 0.5s ease-in-out;
  flex: 0 0 100%;
}
.hidden {
  opacity: 0;
}

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}
</style>