<template>
    <div class="px-5 md:px-24 py-10 bg-gray-100">
					
        <div class="flex items-center font-bold text-4xl">
            <h2 class="mx-auto relative border-b-4 border-b-4 border-custom-yellow hover:border-b-4 hover:border-white">Témoignages</h2>
        </div>
        <div class="flex items-center mt-5">
            <hp class="mx-auto relative">Ce que nos clients et partenaires disent</hp>
        </div>
            
        <div class="carousel xs:mt-5 md:mt-0">
            <div class="carousel-inner" :style="{ transform: `translateX(${currentIndex * -100}%)` }">

              <div v-for="(item, index) in testimonials" :key="index" class="carousel-item flex items-center">
                  <div class="mt-5 md:mt-14 w-full md:w-3/4 mx-auto">
                      <div class="bg-white rounded-lg">
                          <div class='flex p-5'>
                              <img class="shadow-2xl border-4 border-white absolute w-42 h-36 rounded-lg" :src="item.image1">
                              <div class="ml-36 md:ml-44">
                                  <div class="xs:text-xl md:text-2xl  border-b-2 border-custom-yellow pb-5 font-bold text-gray-700">
                                      {{ item.name1 }}
                                  </div>
                                  <div class="font-bold text-gray-700">
                                    <p class="mt-5 xs:text-sm md:text-xl text-gray-500">"{{ item.description1 }}"</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

            </div>
        </div>


    </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "TestimonyComponent",

  data() {
    return {
      showMenu: false,
      
      showImage1: false,
      showImage2: false,
      showImage3: false,

      currentIndex: 0,

	      testimonials: [
          {
            name1: 'Design',
            description1:
              "I don’t know what else to say, this is something that you haven’t seen before. Unique design, impressive & outstanding support.",
            image1: "https://pattechconsulting.com/images/testimonials/test-1.jpg",          
          
            name2: 'Design',
            description2:
              "I don’t know what else to say, this is something that you haven’t seen before. Unique design, impressive & outstanding support.",
            image2: "https://pattechconsulting.com/images/testimonials/test-2.jpg",
            
            name3: 'Design',
            description3:
              "I don’t know what else to say, this is something that you haven’t seen before. Unique design, impressive & outstanding support.",
            image3: "https://pattechconsulting.com/images/testimonials/test-2.jpg",
        }
      
      ],
      autoplayInterval: null
    };
  },

  created() {
    this.startAutoplay();
  },

  methods: {

    
    menuModal() {
      this.showMenu = !this.showMenu;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.items.length) % this.testimonials.length;
    },

	  startAutoplay() {
      this.autoplayInterval = setInterval(this.nextSlide, 4000); // Change slide every 5 seconds (adjust as needed)
    },
    stopAutoplay() {
      clearInterval(this.autoplayInterval);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
    },

  },
}

</script>

<style>

</style>