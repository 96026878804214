<template>
    <div class="px-5 md:px-10 md:px-10 py-14 w-full">
        <div class="w-full">
            <div class="flex font-bold text-4xl">
                <h2 class="text-custom-yellow relative border-b-4 pb-2 border-b-4 border-custom-yellow hover:border-b-4 hover:border-white">Projets</h2>
            </div>
            <div class="flex items-center mt-5">
                <hp class="mx-auto relative">Nos projets réalisés</hp>
            </div>

            <div class="md:grid md:grid-cols-3 md:gap-5 p-5 md:px-14">
                <div class="gap-5 mt-5 md:mt-14 bg-cover bg-center bg-no-repeat bg-white justify-between p-5" style="background-image: url('https://pattechconsulting.com/images/constructions-3.jpg') ">
                    <div class="w-1/3 bg-custom-yellow text-white p-1 text-center">
                        Lorem, ipsum.
                    </div>
                    <div class="mt-48 text-white bg-black bg-opacity-50 p-5">
                        <h3 class="font-bold text-lg border-b-4 border-gray-100 hover:border-b-4 hover:border-custom-yellow">Lorem ipsum dolor sit.</h3>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "ProjectComponent",

  data() {
    return {
    };
  },

  mounted() {
  },

  methods: {
    
  }
};
</script>


<style>
</style>