<template>
    <div>
        <Header-component :titleMessage="indice"></Header-component>

        <div>
			<div class="w-full">
				
				<div class="px-5 md:px-24">
					
				</div>

			
                <ServiceComponent></ServiceComponent>

                <!-- <RealisationComponent></RealisationComponent>             -->

                <div class="px-5 md:px-14 bg-gray-100 gap-4 mt-10 md:mt-10 w-full">
                    <div class="md:flex w-full md:gap-10 py-14 md:justify-between ">
                        <div class="bg-white md:w-1/4 text-center p-5">
                            <p class="font-bold text-4xl text-custom-yellow">8</p>
                            <p class="text-gray-400 mt-2">Années d'expérience</p>
                        </div>
                        <div class="mt-5 md:mt-0 bg-white md:w-1/4 text-center p-5">
                            <p class="font-bold text-4xl text-custom-yellow">6</p>
                            <p class="text-gray-400 mt-2">Services</p>
                        </div>
                        <div class="mt-5 md:mt-0 bg-white md:w-1/4 text-center p-5">
                            <p class="font-bold text-4xl text-custom-yellow">24H / 5J</p>
                            <p class="text-gray-400 mt-2">Disponibilité</p>                        
                        </div>
                        <div class="mt-5 md:mt-0 bg-white md:w-1/4 text-center p-5">
                            <p class="font-bold text-4xl text-custom-yellow">8</p>
                            <p class="text-gray-400 mt-2">Projets</p>                                                
                        </div>
                    </div>
                </div>	

				<footer-component></footer-component>
				

			</div>
		</div>
    </div>

</template>

<script>

    /* eslint-disable */
    import HeaderComponent from "../../src/components/HeaderComponent.vue";
    import FooterComponent from "../../src/components/FooterComponent.vue";
    import ServiceComponent from "../../src/components/ServiceComponent.vue";
    import RealisationComponent from "../../src/components/RealisationComponent.vue";

    
    export default {
        name: "ServiceView",
        components: { HeaderComponent, FooterComponent, ServiceComponent, RealisationComponent },

        data(){
            return{
                indice: 'Service'
            }
        }
    }
    
</script>

<style>

</style>